.hey-future-you {
  background: url("./images/hey-future-you.png") no-repeat right top;
  background-size: contain;
}

button[disabled] {
  opacity: 0.5;
  cursor: default;
}

#snapshot img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stream-container {
  height: 100%;
}

.stream-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
  Square Responsive
*/
.square {
  background: #eee url("./images/picture.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square-content {
  position: absolute;
  width: 100%;
  height: 100%;
}

canvas {
  display: none;
}

/*
  Loading
*/
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
